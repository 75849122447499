/**
 * Combined by jsDelivr.
 * Original files:
 * - /gh/willmyethewebsiteguy/sectionSlider@3.3.17/javascript.min.js
 * - /gh/willmyethewebsiteguy/splitSections@3.2.26/javascript.min.js
 * - /gh/willmyethewebsiteguy/tabs@4.3.008/tabs-javascript.min.js
 * - /gh/willmyethewebsiteguy/customBreakpoint@1.0.001/customBreakpoint.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
